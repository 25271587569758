import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  test:any = { test: "Testear" };
  user:any;
  alertas:any = 0;

  constructor( private _api: ApiService ) { 
    this.user = localStorage.getItem("user");
  }

  ngOnInit() {
    // let num = 0;
    // setInterval(()=>{
    //   num++;
    //   this.test = num;
    // },1000);

    this._api.alertasNuevas({user:this.user}).subscribe((data:any)=>{
      console.log(data);
      if( data.status == 1 ){
        this.alertas = data.data.length;
      }
    });
    let interval = setInterval( ()=>{
      this._api.alertasNuevas({user:this.user}).subscribe((data:any)=>{
        console.log(data);
        // this.alertas = data.data.length;
      },(err:any)=>{
        if( err.status == 403 ){
          clearInterval(interval);
        }
      });
    }, 30000);

  }

  ionViewWillLeave() {
    // alert("Sale de componente");
    
  }

  ngOnDestroy(){
    // alert("Sale de componente");
    
  }

}
