import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePage } from './home.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
    children:[
      {
        path:"objetos",
        children:[
          {
            path:'',
            loadChildren: () => import('./objetos/objetos.module').then( m => m.ObjetosPageModule )
          }
        ]
      },
      {
        path:"mapa",
        children:[
          {
            path:'',
            loadChildren: () => import('./mapa/mapa.module').then( m => m.MapaPageModule )
          }
        ]
      },
      {
        path:"alertas",
        children:[
          {
            path:'',
            loadChildren: () => import('./alertas/alertas.module').then( m => m.AlertasPageModule )
          }
        ]
      },
      {
        path:"cuenta",
        children:[
          {
            path:'',
            loadChildren: () => import('./cuenta/cuenta.module').then( m => m.CuentaPageModule )
          }
        ]
      }
    ]
  },
  {
    path: '',    
    redirectTo: '/home/objetos',
    // loadChildren: () => import('./cuenta/cuenta.module').then( m => m.CuentaPageModule),
    pathMatch: 'full'
  },
  // {
  //   path: 'mapa',
  //   loadChildren: () => import('./mapa/mapa.module').then( m => m.MapaPageModule)
  // },
  // {
  //   path: 'alertas',
  //   loadChildren: () => import('./alertas/alertas.module').then( m => m.AlertasPageModule)
  // },
  // {
  //   path: 'objetos',
  //   loadChildren: () => import('./objetos/objetos.module').then( m => m.ObjetosPageModule)
  // }
];

// const routes: Routes = [
//   {
//     path: '',
//     component: HomePage
//   },
//   {
//     path: 'objetos',
//     loadChildren: () => import('./objetos/objetos.module').then( m => m.ObjetosPageModule)
//   }
// ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}
